import { TryCart } from "@/app/services"
import {
  TryCartState,
  clearTryCart,
  startLoading,
  startTryCartFetching,
  stopLoading,
  stopTryCartFetching,
  updateTryCart,
} from "./tryCartSlice"
import ToastMessage from "@/app/components/shared/ToastMessage"
import { urlWithQueryParams } from "@/app/utils"
import { toast } from "sonner"

const addToTryBasketThunk =
  (productId: number): any =>
  async (dispatch) => {
    const tryBasket = new TryCart()
    dispatch(startLoading())
    try {
      const res = await tryBasket.addToCart(productId)
      dispatch(stopLoading())
      dispatch(updateTryCart(res?.data))
      ToastMessage.success(`محصول مورد نظر به سبد پرو اضافه گردید.`)
    } catch (e) {
      dispatch(stopLoading())
    }
  }

const submitTryBasketThunk =
  (tryCart: TryCartState): any =>
  async (dispatch) => {
    const tryBasket = new TryCart()
    dispatch(startLoading())
    try {
      const res = await tryBasket.submit(tryCart)
      dispatch(stopLoading())
      dispatch(clearTryCart())
      ToastMessage.success(`درخواست ثبت سبد پرو با موفقیت انجام گردید.`)
      window.location.href = urlWithQueryParams(`/try/final/result`, {
        cartId: res?.data?.id,
      })
    } catch (e) {
      dispatch(stopLoading())
      throw new Error(`خطایی صورت گرفته است!`)
    }
  }

const getTryCartThunk = (): any => async (dispatch) => {
  const tryBasket = new TryCart()
  dispatch(startTryCartFetching())
  try {
    const res = await tryBasket.getCart()
    const tryCartData = !!res?.data ?? null
    dispatch(stopTryCartFetching())
    dispatch(updateTryCart(res?.data))
  } catch (e) {
    dispatch(stopTryCartFetching())
    throw new Error(`خطایی صورت گرفته است!`)
  }
}

const removeFromTryCartThunk =
  (productId: number): any =>
  async (dispatch) => {
    const tryBasket = new TryCart()
    dispatch(startLoading())
    try {
      const { data: tryCartData } = await tryBasket.remove(productId)
      dispatch(stopLoading())
      dispatch(updateTryCart(tryCartData))
      ToastMessage.success(`محصول مورد نظر از سبد پرو حذف گردید.`)
    } catch (e) {
      dispatch(stopLoading())
      throw new Error(`خطایی صورت گرفته است!`)
    }
  }

const clearTryBasketCartThunk = (): any => async (dispatch) => {
  const tryBasket = new TryCart()
  try {
    dispatch(startLoading())
    await tryBasket.clear()
    dispatch(stopLoading())
    dispatch(clearTryCart())
  } catch (error) {
    dispatch(stopLoading())
    toast.error("خطایی هنگام پاک شدن سبد پرو رخ داده است")
  }
}

export {
  removeFromTryCartThunk,
  addToTryBasketThunk,
  submitTryBasketThunk,
  getTryCartThunk,
  clearTryBasketCartThunk,
}
