import axiosInstance from "@/api/axios"
import { AddressReducerStateType } from "../(pages)/fa/(v1)/(protected)/(with-header)/checkout/Address"

class ShoppingCartService {
  productId: number | null = null

  constructor(productId?: number) {
    if (productId) this.productId = productId
  }

  async add(qty: number = 1) {
    if (!!!this.productId) throw new Error("Product id is required")

    const res = await axiosInstance.post(`/cart/addItem`, {
      product_id: this.productId,
      quantity: qty,
    })

    return res.data
  }

  async addBulk(
    payload: {
      product_id: number
      quantity: number
    }[]
  ) {
    const res = await axiosInstance.post("/cart/addItem/bulk", payload)
    return res.data
  }

  async remove(qty: number = 1) {
    if (!!!this.productId) throw new Error("Product id is required")
    const res = await axiosInstance.post(`/cart/removeItem`, {
      product_id: this.productId,
      quantity: qty,
    })
    return res.data
  }

  async addDiscount(code: string) {
    const res = await axiosInstance.post(`/cart/discount`, {
      code,
    })
    return res.data
  }

  async removeDiscount() {
    const res = await axiosInstance.delete(`/cart/removeDiscount`)
    return res.data
  }

  async submitAddress(payload: AddressReducerStateType) {
    const res = await axiosInstance.post(`/cart/address`, {
      address_id: payload?.address?.id,
      delivery_date: payload?.delivery_date,
      delivery_method_id: payload?.delivery_method_id,
      delivery_time_id: payload?.delivery_time_id,
    })
    return res.data
  }

  async fetch() {
    const res = await axiosInstance.get(`/cart/show`)
    return res.data
  }

  async clear() {
    const res = await axiosInstance.delete(`/cart/clear`)
    return res.data
  }

  public async payCash(callback?: string) {
    const res = await axiosInstance.post(`/cart/payment`, {
      payment_method: "cash",
      callback,
    })
    return res.data
  }

  public async payC2C(callback?: string) {
    const res = await axiosInstance.post(`/cart/payment`, {
      payment_method: "c2c",
      callback,
    })
    return res.data
  }

  public async verifyC2C(
    tracking_code: string,
    payment_code?: string | null,
    file?: File
  ) {
    const formData = new FormData()

    formData.append("tracking_code", tracking_code)

    if (payment_code) {
      formData.append("payment_code", payment_code)
      formData.append("payment_id", payment_code)
      formData.append("type", "manual")
    }
    if (file) {
      formData.append("file", file)
      formData.append("type", "file")
    }

    const res = await axiosInstance.post(`/cart/verify/c2c`, formData)
    return res.data
  }

  public async payPortal(
    portal_id: number,
    callback: string = `${process.env.NEXT_PUBLIC_HOME_URL}/order/result`
  ) {
    const res = await axiosInstance.post(`/cart/payment`, {
      payment_method: "portal",
      portal_id,
      callback,
    })
    return res.data
  }
}

export default ShoppingCartService
