import store from "../store/store";
import ShoppingCartService from "./shopping-cart";
import { clearClientCart, updateCart } from "../store/slices/cart/cartSlice";
import { ClientCartItemType, SyncProductType } from "../types/cart";
import TryCart from "./try-cart";
import { clearClientTryCart, updateTryCart } from "../store/slices/try-cart/tryCartSlice";
import axiosNext from "@/api/axios-next";
import axiosInstance from "@/api/axios";
import { getCartThunk } from "../store/slices/cart/cartThunk";
import { getTryCartThunk } from "../store/slices/try-cart/tryCartThunk";

export type OtpType = "login" | "register";

class LoginService {
	input: string | null = null;

	constructor(input: string) {
		this.input = input;
	}

	public async getOtp(type: OtpType) {
		if (!type) throw new Error("type is required");

		const res = await axiosNext({
			url: `/api/auth-2/get-otp`,
			method: "POST",
			data: {
				mobile: this.input,
				type,
			},
		});

		return res.data;
	}

	public async syncClientCartAndServer(items: SyncProductType[]) {
		const cart = new ShoppingCartService();
		try {
			await cart.clear();
			const { data: cartData } = await cart.addBulk(items);
			store.dispatch(updateCart(cartData));
			store.dispatch(clearClientCart());
		} catch (e) {
			store.dispatch(getCartThunk());
		}
	}

	public async syncClientTryCartAndServer(items: ClientCartItemType[]) {
		const cart = new TryCart();
		try {
			const { data: cartData } = await cart.addBulk(
				items.map((x) => ({
					product_id: x.product.product_id,
				}))
			);

			store.dispatch(updateTryCart(cartData));
			store.dispatch(clearClientTryCart());
		} catch (e) {
			store.dispatch(getTryCartThunk());
		}
	}

	public async confirmOtp(code: string) {
		const res = await axiosNext({
			url: `/api/auth-2/sign-in/otp`,
			method: "POST",
			data: {
				mobile: this.input,
				code,
			},
		});
		return res.data;
	}

	public async byCredential(password: string) {
		const res = await axiosNext({
			url: `/api/auth-2/sign-in/password`,
			method: "POST",
			data: {
				email: this.input,
				password,
			},
		});
		return res.data;
	}

	public async forgetPassword() {
		const res = await axiosInstance.post(`/auth/forget_password`, {
			email: this.input,
		});
		return res.data;
	}

	public async register(code: string) {
		const res = await axiosNext({
			url: `/api/auth-2/register`,
			method: "POST",
			data: {
				mobile: this.input,
				code,
			},
		});
		return res.data;
	}

	public async checkExistence() {
		const res = await axiosInstance.post(`/auth/checkExistence`, {
			identity: this.input,
		});
		return res.data;
	}
	async changeMobile() {
		const res = await axiosInstance.put(`/user/change_mobile`, {
			mobile: this.input,
		});
		return res.data;
	}
	async confirmChangeMobile(code: string) {
		const res = await axiosInstance.post(`/user/change_mobile/confirm`, {
			mobile: this.input,
			otp: code,
		});
		return res.data;
	}
}

export default LoginService;
