import { AddressReducerStateType } from "@/app/(pages)/fa/(v1)/(protected)/(with-header)/checkout/Address";
import ToastMessage from "@/app/components/shared/ToastMessage";
import { ShoppingCartService } from "@/app/services";
import { clearCart, startFetching, startLoading, stopFetching, stopLoading, updateCart } from "./cartSlice";

const clearCartThunk = (): any => async (dispatch: any) => {
	const cart = new ShoppingCartService();

	dispatch(startLoading());
	try {
		await cart.clear();
		dispatch(clearCart());
		dispatch(stopLoading());
	} catch (e) {
		dispatch(stopLoading());
	}
};

const getCartThunk = (): any => async (dispatch: any) => {
	const cart = new ShoppingCartService();
	dispatch(startFetching());
	try {
		const res = await cart.fetch();
		const cartData = res?.data ?? null;
		dispatch(updateCart(cartData));
		dispatch(stopFetching());
	} catch (e) {
		dispatch(stopFetching());
	}
};

const addToCartThunk =
	(productId: number, qty: number = 1): any =>
	async (dispatch: any) => {
		const cart = new ShoppingCartService(productId);

		dispatch(startLoading());
		try {
			const { data: cartData } = await cart.add(qty);
			dispatch(updateCart(cartData));
			dispatch(stopLoading());
			ToastMessage.success(`محصول مورد نظر به سبد خرید اضافه گردید.`);
		} catch (e) {
			dispatch(stopLoading());
		}
	};

const removeFromCartThunk =
	(productId: number, qty: number = 1): any =>
	async (dispatch: any) => {
		const cart = new ShoppingCartService(productId);

		dispatch(startLoading());
		try {
			const { data: cartData } = await cart.remove(qty);
			dispatch(updateCart(cartData));
			dispatch(stopLoading());
			ToastMessage.success(`محصول مورد نظر از سبد خرید حذف گردید.`);
		} catch (e) {
			dispatch(stopLoading());
		}
	};

const addAddressThunk =
	(payload: AddressReducerStateType): any =>
	async (dispatch: any) => {
		const cart = new ShoppingCartService();

		dispatch(startLoading());
		try {
			const { data: cartData } = await cart.submitAddress(payload);
			dispatch(updateCart(cartData));
			// dispatch(stopLoading())
			return true;
		} catch (e) {
			dispatch(stopLoading());
			return false;
		}
	};

const addCartDiscountThunk =
	(discountCode: string): any =>
	async (dispatch: any) => {
		const cart = new ShoppingCartService();

		dispatch(startLoading());
		try {
			const { data: cartData } = await cart.addDiscount(discountCode);
			dispatch(updateCart(cartData));
			dispatch(stopLoading());
			ToastMessage.success("کد تخفیف به سبد خرید اضافه گردید.");
		} catch (e) {
			dispatch(stopLoading());
		}
	};

const removeCartDiscountThunk = (): any => async (dispatch: any) => {
	const cart = new ShoppingCartService();

	dispatch(startLoading());
	try {
		const { data: cartData } = await cart.removeDiscount();
		dispatch(updateCart(cartData));
		dispatch(stopLoading());
		ToastMessage.success("کد تخفیف از سبد خرید حذف گردید.");
	} catch (e) {
		dispatch(stopLoading());
	}
};

export { addAddressThunk, addCartDiscountThunk, addToCartThunk, clearCartThunk, getCartThunk, removeCartDiscountThunk, removeFromCartThunk };
