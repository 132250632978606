import axiosInstance from "@/api/axios"
import { TryCartState } from "../store/slices/try-cart/tryCartSlice"
import { toast } from "sonner"

class TryCart {
  public async addToCart(productId: number) {
    const res = await axiosInstance({
      url: `/try_basket/add`,
      method: "POST",
      data: {
        product_id: productId,
      },
    })
    return res?.data
  }
  public async getCart() {
    const res = await axiosInstance({
      url: `/try_basket`,
      method: "GET",
    })
    return res?.data
  }
  async addBulk(
    payload: {
      product_id: number
    }[]
  ) {
    const res = await axiosInstance.post("/try_basket/add/bulk", payload)
    return res.data
  }
  async submit(tryCart: TryCartState) {
    if (tryCart?.address === null) {
      toast.error("لطفا ابتدا آدرس خود را انتخاب نمایید")
      throw new Error("لطفا ابتدا آدرس خود را انتخاب نمایید")
    }
    if (tryCart?.deliveryMethod === null) {
      toast.error("لطفا ابتدا شیوه ارسال خود را انتخاب نمایید")
      throw new Error("لطفا ابتدا شیوه ارسال خود را انتخاب نمایید")
    }
    if (tryCart?.deliveryDate === null) {
      toast.error("لطفا ابتدا تاریخ ارسال را انتخاب نمایید")
      throw new Error("لطفا ابتدا تاریخ ارسال را انتخاب نمایید")
    }
    if (tryCart?.deliveryTime === null) {
      toast.error("لطفا ابتدا ساعت ارسال را انتخاب نمایید")
      throw new Error("لطفا ابتدا ساعت ارسال را انتخاب نمایید")
    }

    const res = await axiosInstance.post("/try_basket/submit", {
      address_id: tryCart.address.id,
      delivery_method_id: tryCart.deliveryMethod.id,
      delivery_date: tryCart.deliveryDate,
      delivery_time_id: tryCart.deliveryTime.id,
    })
    return res.data
  }
  async remove(productId: number) {
    const res = await axiosInstance({
      url: "/try_basket/remove",
      method: "POST",
      data: {
        product_id: productId,
      },
    })
    return res.data
  }
  async clear() {
    const res = await axiosInstance({
      url: "/try_basket",
      method: "DELETE",
    })
    return res.data
  }
}

export default TryCart
