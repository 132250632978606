import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { thunk } from "redux-thunk";
import { createPersistStorage } from "./createPersistStorage";
import cartReducer from "./slices/cart/cartSlice";
import tryCartReducer from "./slices/try-cart/tryCartSlice";
import generalSlice from "./slices/general";

const storage = createPersistStorage();

const persistConfig = {
	key: "vahdat-front",
	storage: storage,
	whitelist: ["cart", "tryCart"],
};

const rootReducer = combineReducers({
	cart: cartReducer,
	tryCart: tryCartReducer,
    
    general: generalSlice.reducer
});

const _persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: _persistedReducer,
	middleware: (defaults) => defaults(),
});

// export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

// export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppStore = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
